<template>
  <router-view />
</template>

<script>
  export default {
    name: 'Alien',
    created () {
      this.$store.commit('SET_VALOR', true)
      if (!window.__env.dataCfg.urlApiAlien) { alert('NO SE ENCUENTRA WEBSERVICE') }
    },
  }
</script>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueMask from 'v-mask'
import '@/sass/overrides.sass'

Vue.use(Vuetify)
Vue.use(VueMask)

const theme = {
  secondary: '#28aae2',
  // primary: '#991d1f',
  primary: '#991d1f',
  accent: '#9C27b0',
  info: '#00CAE3',
  gris: '#757575',
  color1: '#334F64',
  color2: '#eaebed',
  color3: '#ffd782',
  color4: '#eaebed',
}

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: {
        primary: '#bf282f', // #E53935
        secondary: '#334F64',
        accent: '#9C27b0',
        info: '#00CAE3',
      },
    },
  },
})

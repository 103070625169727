import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Servidor',
          path: '/servidor',
          component: () => import('@/views/dashboard/consultaServidor'),
        },
        {
          name: 'Servicio',
          path: '/servicio',
          component: () => import('@/views/dashboard/consultaServicio'),
        },
        {
          name: 'Cambio de clave',
          path: '/clave',
          component: () => import('@/views/dashboard/cambioClave'),
        },
        {
          name: 'Cerrar Sesión',
          path: '/cerrar',
          component: () => import('@/views/dashboard/cerrarSesion'),
        },
        {
          name: 'Login',
          path: '*',
          component: () => import('@/views/dashboard/loginUsuario'),
        },
      ],
    },
  ],
})
